<template>
  <div class="services one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Dịch vụ nổi bật</span>
        <div>
          <el-button
            type="primary"
            size="small"
            title="Chọn ảnh từ máy tính của bạn"
            @click="visible = true"
          >
            Thêm
          </el-button>
        </div>
      </el-row>
    </div>
    <div v-if="!data.service_ids.length" class="empty p-3">
      Chưa có dịch vụ!
    </div>
    <el-row v-else type="flex" align="center" class="p-3">
      <img v-for="s in selectedServices" :key="s.id" :src="s.avatar_url" alt />
    </el-row>
    <services-popup
      :services="allServices"
      :visible="visible"
      :selected-services="data.service_ids"
      @close="visible = false"
      @update="handleUpdateServices"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ServicesPopup from '../popups/Services'

export default {
  name: 'Services',
  components: {
    ServicesPopup
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters(['constant']),
    allServices() {
      return this.constant.constants.place_services.filter(
        (s) => s.place_type && s.place_type === this.type
      )
    },
    selectedServices() {
      return this.data.service_ids.map((id) =>
        this.allServices.find((s) => s.id === id)
      )
    }
  },
  methods: {
    handleUpdateServices(ids) {
      this.data.service_ids = ids
    }
  }
}
</script>

<style lang="scss" scoped>
.services {
  img {
    width: 28px;
    height: 28px;
    margin-right: 20px;
  }
}
</style>
