<template>
  <div :class="data.type">
    <shared-show
      :data="data"
      :type="data.type"
      :actions="data.index.actions"
      :inputs="data.show.inputs"
      :lefts="data.show.leftCards"
      :rights="data.show.rightCards"
    />
  </div>
</template>

<script>
import SharedShow from '@/components/shared/show.vue'

export default {
  name: 'SharedPageShow',
  components: {
    SharedShow
  },
  data() {
    return {
      data: this.$route.meta.data
    }
  }
}
</script>

<style lang="scss" scoped></style>
