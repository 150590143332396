<template>
  <el-dialog
    top="80px"
    width="700px"
    class="food-form-popup"
    :title="`Cập nhật kích thước & giá món ăn: ${food.summary}`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form
      ref="form"
      class="pt-2 pr-2"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="12" type="end">
        <el-col :lg="8">
          <el-form-item prop="dish_size">
            <el-select
              v-model="form.dish_size"
              :disabled="callingAPI || loading"
              placeholder="Chọn kích thước"
            >
              <el-option
                v-for="t in dish_sizes"
                :key="t.value"
                :value="t.value"
                :label="t.label"
                :disabled="!!foodSizes.find((f) => f.dish_size === t.value)"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="10">
          <el-form-item prop="price">
            <el-input
              v-model.number="form.price"
              :disabled="callingAPI || loading"
              type="number"
              placeholder="Nhập giá"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="6" align="right">
          <el-form-item>
            <el-button
              type="primary"
              :disabled="
                loading || callingAPI || !form.dish_size || !form.price
              "
              @click="handleAddNewSize"
            >Thêm</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" :data="foodSizes">
      <el-table-column label="STT" type="index" width="50" />
      <el-table-column
        label="Kích thước"
        prop="dish_size"
        width="250"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.dish_size | getDishSize }}
        </template>
      </el-table-column>
      <el-table-column label="Giá (VND)" prop="price">
        <template slot-scope="scope">
          <b v-if="!scope.row.is_edit">{{
            scope.row.price | numberWithDelimiter
          }}</b>
          <el-input
            v-else
            v-model.number="scope.row.new_price"
            :disabled="callingAPI"
            type="number"
            placeholder="Nhập giá"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="Thao tác"
        width="120"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.is_edit"
            icon="el-icon-edit"
            type="primary"
            circle
            size="small"
            :disabled="callingAPI"
            @click="scope.row.is_edit = true"
          ></el-button>
          <el-button
            v-if="!scope.row.is_edit"
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            :disabled="callingAPI || scope.row.dish_size === 'M'"
            @click="handleDelete(scope.row)"
          ></el-button>
          <el-button
            v-if="scope.row.is_edit"
            icon="el-icon-check"
            type="success"
            circle
            size="small"
            :disabled="callingAPI"
            @click="handleUpdate(scope.row)"
          ></el-button>
          <el-button
            v-if="scope.row.is_edit"
            icon="el-icon-close"
            circle
            type="danger"
            size="small"
            :disabled="callingAPI"
            @click="scope.row.is_edit = false"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Đóng</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import DISH from '@/data/dish'
import { mapGetters, mapActions } from 'vuex'
import { createFood, updateFood, getFoods, deleteFood } from '@/services/food'

export default {
  name: 'UpdateFoodPrices',
  props: {
    visible: Boolean,
    food: Object,
    object: Object
  },
  data() {
    return {
      loading: false,
      callingAPI: false,
      labels: ['favorites', 'chilren'],
      form: {
        price: '',
        dish_size: ''
      },
      dish_sizes: DISH.SIZES,
      foodSizes: [],
      rules: {
        dish_size: [
          { required: true, message: 'Chưa chọn kích thước', trigger: ['blur', 'change'] }
        ],
        price: [
          { required: true, message: 'Chưa nhập giá', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        place_id: this.object.id,
        locale: this.language,
        page: 1,
        per_page: 10,
        dish_size_group_id: this.food.dish_size_group_id
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    ...mapActions('food', ['updateReloadFoodGroup']),
    loadData() {
      this.loading = true
      getFoods(this.params).then((response) => {
        this.foodSizes = response.result.map((d, index) => ({
          ...d,
          index,
          price: d.unit_price.amount,
          new_price: d.unit_price.amount,
          is_edit: false,
          is_delete: false
        }))
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleDelete(food) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.callingAPI = true
        deleteFood({ id: food.id })
          .then(() => {
            this.foodSizes = this.foodSizes.filter((a) => a.id !== food.id)
            this.callingAPI = false
          })
          .catch(() => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa!'
            })
          })
      })
    },

    handleAddNewSize() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createFood({
            summary: this.food.summary,
            order: this.food.order,
            description: this.food.description,
            labels: this.food.labels,
            dish_type: this.food.dish_type,
            locale: this.food.locale,
            place_id: this.food.place_id,
            dish_size_group_id: this.food.dish_size_group_id,
            dish_size: this.form.dish_size,
            price: this.form.price
          }).then((response) => {
            this.callingAPI = false
            this.$refs.form.resetFields()
            this.loadData()
            this.$notify.success({
              title: 'Thông báo',
              message: 'Thêm thành công'
            })
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },

    handleUpdate(food) {
      if (food.new_price && food.new_price > 0) {
        this.callingAPI = true
        updateFood({ ...food, price: food.new_price }).then(() => {
          this.callingAPI = false
          food.is_edit = false
          food.price = food.new_price
          this.updateReloadFoodGroup(dayjs().unix())
          this.$notify.success({
            title: 'Thông báo',
            message: 'Cập nhật món ăn thành công'
          })
        }).catch((error) => {
          this.callingAPI = false
          this.$notify.error({
            title: 'Thông báo',
            message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
          })
        })
      } else {
        this.$message.warning({
          message: 'Giá không đúng định dạng'
        })
      }
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.food-form-popup {
  .el-form {
    max-height: 550px;
    overflow: auto;
  }
}
</style>
