<template>
  <div class="groups one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Danh sách liên kết ({{ groups.length }})</span>
        <div>
          <el-input
            v-model="newGroupName"
            size="small"
            placeholder="Nhập vào tên nhóm để thêm mới"
            :disabled="callingAPI"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            :disabled="loading || !newGroupName.trim()"
            @click="handleCreateGroup"
          >Thêm</el-button>
        </div>
      </el-row>
    </div>
    <div v-if="!groups.length" v-loading="loading" class="empty p-3">
      Chưa có nhóm!
    </div>
    <el-row v-else class="p-3">
      <div v-for="group in groups" :key="group.id" class="group mb-3">
        <el-row
          class="group__header p-2"
          type="flex"
          justify="space-between"
          align="middle"
        >
          <el-row
            v-if="editGroup && editGroup.id === group.id"
            class="m-0"
            type="flex"
            align="middle"
          >
            <el-input
              v-model="editGroup.name"
              size="small"
              :disabled="callingAPI"
            ></el-input>
            <i
              class="ml-3 el-icon-check text-success"
              title="Lưu"
              @click="handleUpdateGroup"
            ></i>
            <i
              v-if="!callingAPI"
              class="ml-3 el-icon-close text-danger"
              title="Hủy"
              @click="editGroup = null"
            ></i>
          </el-row>
          <el-row v-else class="m-0" type="flex" align="middle">
            <b class="text-primary">{{ group.name }}</b>
            <i
              class="ml-3 el-icon-edit text-primary"
              title="Sửa tên nhóm"
              @click="editGroup = JSON.parse(JSON.stringify(group))"
            ></i>
          </el-row>
          <div>
            <el-button
              type="primary"
              size="small"
              title="Thêm dữ liệu vào nhóm"
              @click="handleOpenItemPopup(group, null)"
            >
              <i class="el-icon-plus"></i>
            </el-button>
            <el-button
              type="danger"
              size="small"
              title="Xóa nhóm"
              @click="handleDeleteGroup(group)"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </div>
        </el-row>
        <div class="group__content p-2">
          <div
            v-if="!group.items.length"
            v-loading="group.loading"
            class="group__content--empty p-3"
          >
            Chưa có dữ liệu!
          </div>
          <el-row class="group__content--items">
            <el-col
              v-for="item in group.items"
              :key="item.id"
              class="item"
              :lg="12"
              @click.native="handleOpenItemPopup(group, item)"
            >
              <el-row type="flex" align="middle" justify="space-between">
                <img
                  :src="
                    item.object.avatar && item.object.avatar.url
                      ? item.object.avatar.url
                      : imageDefaultUrl
                  "
                  class="mr-2"
                  alt="href"
                />
                <div class="item-name">
                  <p class="text-limited mr-2" :title="item.object.name">
                    {{ item.object.name }}
                  </p>
                </div>
                <i
                  class="el-icon-error text-danger"
                  @click.stop="handleDeleteItem(item, group)"
                ></i>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-row>
    <group-item-popup
      v-if="itemVisible"
      :visible="itemVisible"
      :type="object_type"
      :group="selectedGroup"
      :item="selectedItem"
      @close="itemVisible = false"
      @reload="getGroupItems(selectedGroup)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { listGroupsByObject, listGroupItems, updateGroup, createGroup, deleteGroup, deleteItem } from '@/services/group'
import GroupItemPopup from '../popups/GroupItem.vue'

import TYPE from '@/data/type'

export default {
  name: 'Groups',
  components: {
    GroupItemPopup
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      loading: true,
      callingAPI: false,
      groups: [],
      newGroupName: '',
      editGroup: null,
      imageDefaultUrl: '/default-image.jpeg',
      selectedGroup: null,
      selectedItem: null,
      itemVisible: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    object_type() {
      return TYPE.PLACES.find((p) => p.value === this.type).type
    },
    params() {
      const p = {
        locale: this.language
      }
      if (this.object_type === 'place') {
        return { place_id: this.data.id, ...p }
      }
      return { object_id: this.data.id, object_type: this.object_type, ...p }
    }
  },
  beforeMount() {
    this.getGroups()
  },
  methods: {
    getGroups() {
      this.loading = true
      this.groups = []
      const request = listGroupsByObject(this.params, this.object_type)
      request.then((response) => {
        this.groups = response.result.map((g) => ({ ...g, items: [], loading: true }))
        this.groups.forEach(g => {
          this.getGroupItems(g)
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    getGroupItems(group) {
      group.loading = true
      const params = { group_id: group.id, locale: this.language }
      listGroupItems(params, this.object_type).then((response) => {
        group.items = response.result
        group.loading = false
      }).catch(() => {
        group.loading = false
      })
    },

    handleUpdateGroup() {
      if (!this.handValidateName(this.editGroup.name)) {
        this.$notify.warning({
          title: 'Thông báo',
          message: 'Tên nhóm đã tồn tại.'
        })
        return
      }
      this.callingAPI = true
      updateGroup({
        id: this.editGroup.id,
        locale: this.language,
        name: this.editGroup.name
      }, this.object_type).then(() => {
        this.callingAPI = false
        this.groups = this.groups.map((g) => ({
          ...g,
          name: this.editGroup.id === g.id ? this.editGroup.name : g.name
        }))
        this.editGroup = null
        this.$notify.success({
          title: 'Thông báo',
          message: 'Đổi tên nhóm thành công.'
        })
      }).catch(() => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể sửa tên nhóm.'
        })
      })
    },

    handleCreateGroup() {
      if (!this.handValidateName(this.newGroupName)) {
        this.$notify.warning({
          title: 'Thông báo',
          message: 'Tên nhóm đã tồn tại.'
        })
        return
      }
      this.callingAPI = true
      let data = {
        locale: this.language,
        name: this.newGroupName
      }
      data = this.object_type === 'place' ? { ...data, place_id: this.data.id } : { ...data, object_id: this.data.id, object_type: this.object_type }
      createGroup(data, this.object_type).then(() => {
        this.callingAPI = false
        this.newGroupName = ''
        this.getGroups()
        this.$notify.success({
          title: 'Thông báo',
          message: 'Tạo nhóm mới thành công.'
        })
      }).catch(() => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể tạo nhóm.'
        })
      })
    },

    handleDeleteGroup(group) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.callingAPI = true
        deleteGroup({ ids: group.id }, this.object_type)
          .then(() => {
            this.groups = this.groups.filter((g) => g.id !== group.id)
            this.callingAPI = false
            this.$notify.success({
              title: 'Thông báo',
              message: 'Xóa nhóm thành công.'
            })
          })
          .catch(() => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa nhóm!'
            })
          })
      })
    },

    handleDeleteItem(item, group) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        group.loading = true
        deleteItem({ ids: item.id }, this.object_type)
          .then(() => {
            group.items = group.items.filter((i) => i.id !== item.id)
            group.loading = false
            this.$notify.success({
              title: 'Thông báo',
              message: 'Xóa item thành công.'
            })
          })
          .catch(() => {
            group.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa item!'
            })
          })
      })
    },

    handValidateName(name) {
      return !this.groups.find((g) => g.name === name)
    },

    handleOpenItemPopup(group, item = null) {
      this.selectedGroup = group
      this.selectedItem = item
      this.itemVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.groups {
  .group {
    border: 1px solid #ebeef5;
    &__header {
      background-color: #ebeef5;
      i {
        font-weight: bold;
      }
    }
    &__content {
      &--empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--items {
        .item-name {
          width: calc(100% - 90px);
        }
        .item {
          cursor: pointer;
          padding: 10px 20px;
          &:hover {
            background-color: #40a0ff2d;
            border-radius: 10px;
          }
          .el-icon-error {
            font-size: 24px;
          }
          img {
            width: 48px;
            height: 48px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
