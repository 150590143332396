<template>
  <div class="parent one-component-detail-admin">
    <div class="title-component-admin">{{ currentType.name }} cha</div>
    <el-form :model="data" class="p-3">
      <el-form-item>
        <el-select
          v-model="data.parent_id"
          class="w-100"
          filterable
          remote
          :placeholder="`Chọn ${currentType.name} cha`"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option v-for="p in places" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPlaceTypes } from '@/utils/filters'
import { listPlaces } from '@/services/place'

export default {
  name: 'PlaceParent',
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      searchText: '',
      searching: false,
      places: [
        { id: this.data.parent_id, name: this.data.parent.name || '' }
      ]
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    currentType() {
      return getPlaceTypes().find((p) => p.value === this.type)
    },
    params() {
      return {
        locale: this.language,
        place_types: this.type,
        per_page: 10,
        search_text: this.searchText,
        only_parent: true
      }
    }
  },
  methods: {
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
