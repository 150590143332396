<template>
  <div class="status one-component-detail-admin">
    <div class="title-component-admin">Trạng thái</div>
    <el-form :model="data" class="p-3">
      <el-form-item>
        <el-select v-model="data.status" disabled>
          <el-option v-for="s in statuses" :key="s.value" :label="s.label" :value="s.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Status',
  props: {
    data: Object,
    type: String
  },
  computed: {
    ...mapGetters(['constant']),
    statuses() {
      return [
        { value: 'running', label: 'Đang chạy' },
        { value: 'waiting', label: 'Đang chờ' },
        { value: 'finished', label: 'Kết thúc' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  .el-select {
    width: 100%;
  }
}
</style>
