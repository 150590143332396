<template>
  <div class="foods one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Danh sách món ăn</span>
        <div>
          <el-input
            v-model="searchText"
            suffix-icon="el-icon-search"
            placeholder="Tìm kiếm theo tên"
            size="small"
            @input="handleGetFoods"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            title="Thêm"
            :disabled="loading"
            @click="handleOpenFormPopup(null)"
          >Thêm</el-button>
        </div>
      </el-row>
    </div>
    <div class="p-3">
      <el-tabs v-model="dishType">
        <el-tab-pane
          v-for="tab in dishTypes"
          :key="tab.value"
          :label="`${tab.label} ${
            dishType === tab.value && !loading ? `(${total})` : ''
          }`"
          :name="tab.value"
        />
      </el-tabs>
      <el-table v-loading="loading" :data="foods">
        <el-table-column label="STT" type="index" width="50"></el-table-column>
        <el-table-column width="60">
          <template slot-scope="scope">
            <img
              :src="
                scope.row.avatar && scope.row.avatar.url
                  ? scope.row.avatar.url
                  : imageDefaultUrl
              "
              alt="href"
            />
          </template>
        </el-table-column>
        <el-table-column label="Tên món ăn" prop="summary" min-width="100">
          <template slot-scope="scope">
            <a href="javascript:;" @click="handleOpenFormPopup(scope.row)">{{
              scope.row.summary
            }}</a>
          </template>
        </el-table-column>
        <el-table-column label="Mô tả" prop="description"></el-table-column>
        <el-table-column
          label="Ngày tạo"
          prop="created_at"
          width="100"
        ></el-table-column>
        <el-table-column
          label="Hiển thị"
          prop="summary"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_hidden"
              active-color="#13ce66"
              :active-value="false"
              :inactive-value="true"
              @change="handleUpdateFood(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="Thao tác"
          width="150"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-tooltip content="Chỉnh sửa thông tin" placement="top">
              <el-button
                icon="el-icon-edit"
                type="primary"
                circle
                size="small"
                @click="handleOpenFormPopup(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="Kích thước và giá" placement="top">
              <el-button
                icon="el-icon-money"
                type="primary"
                circle
                size="small"
                @click="handleOpenPricesPopup(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="Xóa món ăn" placement="top">
              <el-button
                icon="el-icon-delete"
                circle
                type="danger"
                size="small"
                @click="handleDeleteFood(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" align="middle" justify="end" class="mt-2">
        <el-pagination
          :current-page.sync="page"
          :page-sizes="[10, 15, 20, 30]"
          :page-size.sync="limit"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleChangeSize"
          @current-change="handleChangePage"
        ></el-pagination>
      </el-row>
    </div>
    <food-popup
      v-if="formVisible"
      :visible="formVisible"
      :food="selectedFood"
      :object="data"
      :type="dishType"
      @close="formVisible = false"
      @reload="handleGetFoods"
    />
    <food-prices-popup
      v-if="formPricesVisible"
      :visible="formPricesVisible"
      :food="selectedFood"
      :object="data"
      @close="formPricesVisible = false"
    />
  </div>
</template>

<script>
import DISH from '@/data/dish'
import { mapGetters } from 'vuex'
import { getFoods, deleteFood, updateFood } from '@/services/food'
import FoodPopup from '../popups/Food.vue'
import FoodPricesPopup from '../popups/FoodPrices.vue'

export default {
  name: 'Foods',
  components: {
    FoodPopup,
    FoodPricesPopup
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      dishType: DISH.TYPES[0].value,
      dishTypes: DISH.TYPES,
      loading: true,
      foods: [],
      formVisible: false,
      formPricesVisible: false,
      selectedFood: null,
      limit: 10,
      total: 0,
      page: 1,
      imageDefaultUrl: '/default-image.jpeg',
      searchText: ''
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        place_id: this.data.id,
        locale: this.language,
        order: '-order',
        search_text: this.searchText,
        page: this.page,
        per_page: this.limit,
        dish_type: this.dishType,
        dish_size: 'M'
      }
    }
  },
  watch: {
    dishType: 'handlReload'
  },
  beforeMount() {
    this.handleGetFoods()
  },
  methods: {
    handleGetFoods() {
      this.loading = true
      this.foods = []
      getFoods(this.params).then((response) => {
        this.foods = response.result
        this.total = response.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleOpenFormPopup(food) {
      this.selectedFood = food
      this.formVisible = true
    },

    handleOpenPricesPopup(food) {
      this.selectedFood = food
      this.formPricesVisible = true
    },

    handleDeleteFood(food) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteFood({ id: food.id })
          .then(() => {
            this.foods = this.foods.filter((a) => a.id !== food.id)
            this.loading = false
            this.total = this.total - 1
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa món ăn này!'
            })
          })
      })
    },

    handleUpdateFood(food) {
      updateFood(food).then(() => {
        this.$notify.success({
          title: 'Thông báo',
          message: 'Cập nhật món ăn thành công'
        })
      }).catch((error) => {
        this.$notify.error({
          title: 'Thông báo',
          message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
        })
      })
    },

    handleChangePage(page) {
      this.page = page
      this.handleGetFoods()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.handleGetFoods()
    },

    handlReload() {
      this.page = 1
      this.handleGetFoods()
    }
  }
}
</script>

<style lang="scss" scoped>
.foods {
  img {
    width: 48px !important;
    height: 48px !important;
    object-fit: cover;
    border-radius: 50%;
  }
  a {
    color: #1174d4;
  }
}
</style>
