<template>
  <div v-loading="loading" class="parent one-component-detail-admin">
    <div class="title-component-admin">Cấu trúc</div>
    <el-form :model="data" class="p-3">
      <el-form-item v-if="isShowDashboard" label="Trang chủ">
        <el-select
          v-model="data.dashboard_id"
          class="w-100"
          filterable
          remote
          placeholder="Chọn trang chủ"
          :loading="dashboardSearching"
          :remote-method="(text) => searchPlace(text, 'dashboard')"
        >
          <el-option
            v-for="p in dashboards"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isShowMuseum" label="Không gian văn hoá">
        <el-select
          v-model="data.museum_id"
          class="w-100"
          filterable
          remote
          placeholder="Chọn Không gian văn hoá"
          :loading="museumSearching"
          :remote-method="(text) => searchPlace(text, 'museum')"
        >
          <el-option
            v-for="p in museums"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isShowKiosk" label="Kiosk">
        <el-select
          v-model="data.kiosk_id"
          class="w-100"
          filterable
          remote
          placeholder="Chọn Kiosk"
          :loading="kioskSearching"
          :remote-method="(text) => searchPlace(text, 'kiosk')"
        >
          <el-option
            v-for="p in kiosks"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { listPlaces, getPlaceById } from '@/services/place'

export default {
  name: 'Structure',
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      searchText: '',
      loading: false,
      dashboardSearching: false,
      museumSearching: false,
      kioskSearching: false,
      dashboards: [],
      museums: [],
      kiosks: []
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    isShowDashboard() {
      return ['museum'].includes(this.type)
    },
    isShowMuseum() {
      return ['kiosk', 'lookup', 'event'].includes(this.type)
    },
    isShowKiosk() {
      return ['lookup'].includes(this.type)
    },
    params() {
      return {
        locale: this.language,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      if (this.isShowDashboard && this.data.dashboard_id) {
        await this.getPlace('dashboard')
      }
      if (this.isShowMuseum && this.data.museum_id) {
        await this.getPlace('museum')
      }
      if (this.isShowKiosk && this.data.kiosk_id) {
        await this.getPlace('kiosk')
      }
      this.loading = false
    },
    async getPlace(type) {
      await getPlaceById({
        id: this.data[`${type}_id`],
        locale: this.language
      }).then((res) => {
        this[`${type}s`] = [res]
      })
    },
    getPlaces(type) {
      this[`${type}Searching`] = true
      const request = listPlaces({
        ...this.params,
        place_types: type
      })
      request.then((res) => {
        this[`${type}s`] = res.result
        this[`${type}Searching`] = false
      }).catch(() => {
        this[`${type}Searching`] = false
      })
    },

    searchPlace(text, type) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces(type)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
