<template>
  <editor
    v-model="inputValue"
    :api-key="null"
    :init="editorConfig"
    @input="handleChangeContent"
  />
</template>

<script>
import CONSTANTS from '@/config/constants'
import Editor from '@tinymce/tinymce-vue'
import { createStorage } from '@/services/storage'

export default {
  name: 'EditorType',
  components: {
    editor: Editor
  },
  props: {
    editorKey: String,
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputValue: this.value,
      apiKey: CONSTANTS.EDITOR_API_KEY,
      editorConfig: {
        min_height: 350,
        language: 'vi',
        language_url: '/tinymce/langs/vi.js',
        icons: 'small',
        icons_url: '/tinymce/icons/small/icons.js',
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table'
        ],
        toolbar: [
          {
            name: 'history', items: ['undo', 'redo']
          },
          {
            name: 'styles', items: ['fontselect']
          },
          {
            name: 'styles', items: ['fontsizeselect']
          },
          {
            name: 'formatting', items: ['bold', 'italic', 'underline', 'forecolor', 'backcolor']
          },
          {
            name: 'alignment', items: ['align']
          },
          {
            name: 'indentation', items: ['bullist', 'numlist', 'outdent', 'indent']
          },
          {
            name: 'insert', items: ['link', 'image']
          },
          {
            name: 'more', items: ['removeformat', 'strikethrough', 'blockquote']
          }
        ],
        font_formats: 'Sans Serif=Arial,Helvetica,sans-serif;Serif=Times New Roman,Times,serif;' +
                      'Fixed Width=Courier New,Courier,monospace;Wide=Arial Black,Helvetica,sans-serif;' +
                      'Narrow=Arial Narrow,Helvetica,sans-serif;Comic Sans MS=Comic Sans MS, cursive,sans-serif;' +
                      'Garamond=Garamond,Times New Roman,serif;Georgia=Georgia,serif;Tahoma=Tahoma,Geneva,sans-serif;' +
                      'Trebuchet MS=Trebuchet MS,Helvetica,sans-serif;Verdana=Verdana,Geneva,sans-serif',
        toolbar_mode: 'floating',
        images_upload_handler: (blobInfo, success, failure) => {
          const data = {
            file: blobInfo.blob(),
            objectId: '',
            fileType: 'image',
            objectType: '',
            locale: 'vi'
          }
          createStorage(data).then((res) => {
            success(res.data.url)
          }).catch((error) => {
            failure(`HTTP Error: ${error.response.status}`)
            return
          })
        }
      }
    }
  },
  methods: {
    handleChangeContent(data) {
      this.$emit('change', data)
    }
  }
}
</script>

<style lang="scss">
</style>
