<template>
  <div class="packages one-component-detail-admin">
    <div class="title-component-admin">Packages</div>
    <div class="p-3">
      <package-selects :object="data" :type="type" :auto-update="true" />
    </div>
  </div>
</template>

<script>
import PackageSelects from '../items/Packages'

export default {
  name: 'Packages',
  components: {
    PackageSelects
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
