<template>
  <el-dialog
    top="80px"
    title
    class="show-video"
    width="1020px"
    :visible="visible"
    :show-close="false"
    append-to-body
    @close="$emit('close')"
  >
    <div v-if="visible" class="show-video__content">
      <video width="100%" controls autoplay>
        <source :src="video.url" type="video/mp4" />
      </video>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ShowVideoPopup',
  props: {
    visible: Boolean,
    video: Object
  }
}
</script>
<style lang="scss" scoped>
.show-video {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
</style>
<style lang="scss">
.show-video {
  .el-dialog {
    &__header {
      display: none;
    }
    &__body {
      padding: 10px;
      height: 600px;
    }
  }
}
</style>
