<template>
  <div class="icon">
    <img :src="url" alt="href" />
  </div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    url: {
      type: String
    }
  }
}
</script>

<style lang="scss">
@keyframes shake {
  from {
    transform: rotate(-2deg);
  }
  to {
    transform: rotate(2deg);
  }
}

.icon {
  background-size: cover;
  position: relative;
  background-color: transparent;
  margin: 10px;
  height: 90%;
  border-radius: 10px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.07);
  color: #777;
  font-weight: 900;
  font-size: 12px;
  line-height: 52px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    pointer-events: none;
  }
}

.v-grid-item-dragging .icon {
  animation-name: shake;
  animation-duration: 0.08s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>
