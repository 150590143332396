<template>
  <div class="display one-component-detail-admin">
    <div class="title-component-admin">Hiển thị</div>
    <el-form :model="data" class="p-3">
      <el-row type="flex">
        <el-radio v-model="data.public" :label="true">Public</el-radio>
        <el-radio v-model="data.public" :label="false">Private</el-radio>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'CampaignDisplay',
  props: {
    data: Object
  }
}
</script>

<style lang="scss" scoped></style>
