<template>
  <div class="services one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Danh sách tiện nghi</span>
        <div>
          <el-button
            type="primary"
            size="small"
            title="Chọn ảnh từ máy tính của bạn"
            @click="visible = true"
          >
            Thêm
          </el-button>
        </div>
      </el-row>
    </div>
    <div v-if="!selectedFacility.length" class="empty p-3">
      Chưa có tiện nghi!
    </div>
    <div v-else class="list-facility">
      <div v-for="item in selectedFacility" :key="item.id" class="tag">{{ item.name }}</div>
    </div>
    <facility-popup
      :visible="visible"
      :all-facility="allFacility"
      :selected-facility="facility_ids"
      @updateFacilities="updateFacilities"
      @close="visible = false"
    ></facility-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FacilityPopup from '@/components/popups/Facility'
export default {
  name: 'ListFacility',
  components: {
    FacilityPopup
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      visible: false,
      facility_ids: []
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    allFacility() {
      return this.constant.constants.place_facilities.filter((p) => p.place_type === 'stay')
    },
    selectedFacility() {
      return this.allFacility.filter((f) => this.facility_ids.includes(f.id))
    }
  },
  mounted() {
    this.facility_ids = this.data.facility_ids
  },
  methods: {
    updateFacilities(data) {
      this.facility_ids = []
      data.forEach(group => {
        const selectedFacilities = group.facilities.filter((f) => f.checked).map((item) => item.id)
        this.facility_ids = this.facility_ids.concat(selectedFacilities)
      })
      this.data.facility_ids = this.facility_ids
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.list-facility {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 10px;
  .tag {
    padding: 5px 20px;
    margin: 5px;
    border-radius: 20px;
    border: 1px solid #409EFF;
    color: #409EFF;
    font-size: 13px;
  }
}
</style>
