<template>
  <div class="hours one-component-detail-admin">
    <div class="title-component-admin">Giờ hoạt động</div>
    <el-form :model="data.opening_time" class="p-3">
      <el-row type="flex" justify="space-between" :gutter="12">
        <el-col :lg="12">
          <el-form-item label="Giờ mở cửa" prop="open_time">
            <el-time-select
              v-model="data.opening_time.open_time"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45',
                maxTime: data.opening_time.close_time,
              }"
              size="medium"
              placeholder="Chọn giờ"
            ></el-time-select>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Giờ đóng cửa" prop="close_time">
            <el-time-select
              v-model="data.opening_time.close_time"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45',
                minTime: data.opening_time.open_time,
              }"
              size="medium"
              placeholder="Chọn giờ"
            ></el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Hours',
  props: {
    data: Object
  }
}
</script>

<style lang="scss" scoped>
.hours {
  .el-date-editor {
    width: 100% !important;
  }
}
</style>
