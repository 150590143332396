<template>
  <div class="rooms one-component-detail-admin">
    <div class="title-component-admin">Hạng phòng</div>
    <el-form :model="form" class="p-3"></el-form>
  </div>
</template>

<script>
export default {
  name: 'Rooms',
  data() {
    return {
      form: {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
