<template>
  <div class="campaign-type one-component-detail-admin">
    <div class="title-component-admin">Loại campaign</div>
    <el-form :model="data" class="p-3">
      <el-form-item>
        <el-select v-model="data.campaign_type" disabled>
          <el-option v-for="s in campaignTypes" :key="s.value" :label="s.label" :value="s.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CampaignType',
  props: {
    data: Object,
    type: String
  },
  computed: {
    ...mapGetters(['constant']),
    campaignTypes() {
      return [
        { value: 'popup', label: 'Popup' },
        { value: 'notification', label: 'Notification' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-type {
  .el-select {
    width: 100%;
  }
}
</style>
