<template>
  <div class="days one-component-detail-admin">
    <div class="title-component-admin">Ngày hoạt động</div>
    <div class="list-day">
      <div
        v-for="day in allDays"
        :key="day.value"
        class="list-day__one"
        :class="{ active: day.active }"
        @click="hanldeChangeDay(day)"
      >
        {{ day.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Days',
  props: {
    data: Object
  },
  computed: {
    allDays() {
      return [0, 1, 2, 3, 4, 5, 6].map((v) => ({
        value: v,
        label: v === 6 ? 'CN' : `T${v + 2}`,
        active: this.data.opening_day.includes(v)
      }))
    }
  },
  methods: {
    hanldeChangeDay(day) {
      day.active = !day.active
      this.data.opening_day = this.allDays
        .filter((d) => d.active)
        .map((d) => d.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.days {
  .list-day {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    &__one {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #ff5252;
      font-size: 13px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      &.active {
        background-color: #4caf50;
      }
    }
  }
}
</style>
