<template>
  <el-dialog
    top="80px"
    title="Danh sách các tiện nghi"
    class="services-popup"
    width="780px"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div v-for="group in groupFacility" :key="group.id">
      <h2>{{ group.name }}</h2>
      <el-row :gutter="24">
        <el-col
          v-for="facility in group.facilities"
          :key="facility.id"
          :lg="12"
        >
          <el-checkbox
            v-model="facility.checked"
            border
            size="large"
            @change="(value) => (facility.checked = value)"
          >{{ facility.name }}</el-checkbox>
        </el-col>
      </el-row>
    </div>
    <el-row
      slot="footer"
      class="footer pt-3"
      type="flex"
      justify="space-between"
      align="center"
    >
      <el-checkbox
        v-model="selectAll"
        size="medium"
        @change="handleSelectAll"
      >Chọn tất cả</el-checkbox>
      <el-button type="primary" @click="handleCompleted">Hoàn tất</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: 'FacilityPopup',
  props: {
    visible: Boolean,
    allFacility: Array,
    selectedFacility: Array
  },
  data() {
    return {
      groupFacility: [],
      selectAll: false
    }
  },
  watch: {
    visible() {
      const facilities = this.allFacility.map((s) => ({
        ...s,
        checked: this.selectedFacility.includes(s.id)
      }))
      const groups = facilities.filter((f) => !f.parent_id)
      this.groupFacility = groups.map((g) => ({
        ...g,
        facilities: facilities.filter((f) => f.parent_id === g.id)
      }))
    }
  },
  methods: {
    handleSelectAll() {
      this.groupFacility = this.groupFacility.map((g) => ({
        ...g,
        facilities: g.facilities.map((f) => ({
          ...f,
          checked: this.selectAll
        }))
      }))
    },
    handleCompleted() {
      this.$emit('updateFacilities', this.groupFacility)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
